@import '~@angular/material/prebuilt-themes/indigo-pink.css';

.custom-toolbar {
    color: red
}

.spacer {
    flex: 1 1 auto;
}

mat-card {
    width: 95%;
    height: 600px;
}

html {
    overflow: hidden;
}

.mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-container-color: #FF6600 !important;
    --mdc-protected-button-label-text-color: #fff;
}

.custom-snackbar-success {
    --mdc-snackbar-container-color: #fff;
    --mat-snack-bar-button-color: #FF6600;
    --mdc-snackbar-supporting-text-color: #FF6600;
    padding-left: 30px;

    .mat-mdc-simple-snack-bar::before {
        content: 'verified';
        font-family: 'Material Icons';
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        color: #FF6600;
    }
}

.custom-snackbar-error {
    --mdc-snackbar-container-color: #fff;
    --mat-snack-bar-button-color: #FF6600;
    --mdc-snackbar-supporting-text-color: #FF6600;
    padding-left: 30px;

    .mat-mdc-simple-snack-bar::before {
        content: 'warning';
        font-family: 'Material Icons';
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;
        color: red;
    }
}

.mat-mdc-snack-bar-label {
    padding-left: 20px;
}

.mat-dialog-container {
    z-index: 1050 !important;
  }
  